'use strict';

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
    homeshow: function () { },
    productshow: function () { },
    productshowincategory: function () { },
    searchshow: function () {
        if (window.gtmEnabled) {
            $('body').on('click', '.product .image-container a:not(.quickview), .product .pdp-link a', function () {
                var $ele = $(this).closest('.product');
                var gtmdata = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
                productClick(gtmdata);
            });
        }
    },
    cartshow: function () { },
    checkoutbegin: function () { },
    orderconfirm: function () { },
    // events that should happen on every page
    all: function () {
        // Add to Cart
        $('body').on('click', '.add-to-cart, .add-to-cart-global', function () {
            if (!$(this).hasClass('isDisabled') && !$(this).hasClass('disabled')) {
                var $ele = $(this);
                var gtmData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
                var gtmGA4Data = $ele.data('gtmga4data') || $.parseJSON($ele.attr('data-gtmga4data'));
                var qty = $ele.closest('.product-wrapper').find('.quantity-select').val();
                qty = qty ? qty : 1;

                if (window.gtmEnabled) {
                    addToCart(gtmData, qty);
                }

                if (window.gtmGA4Enabled) {
                    addToCartGA4(gtmGA4Data, qty);
                }
            }
        });

        // Remove from Cart
        $('body').on('click', '.remove-product', function () {
            var $ele = $(this);
            var gtmData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
            var gtmGA4Data = $ele.data('gtmga4data') || $.parseJSON($ele.attr('data-gtmga4data'));
            var qty = $ele.closest('.card').find('select.quantity').val();
            qty = qty ? qty : 1;

            $('body').on('click', '#removeProductModal .cart-delete-confirmation-btn', function () {
                if (window.gtmEnabled) {
                    removeFromCart(gtmData, qty);
                }

                if (window.gtmGA4Enabled) {
                    removeFromCartGA4(gtmGA4Data, qty);
                }
            });
        });

        // Update GTM data attribute
        $('body').on('product:updateAddToCart', function (e, response) {
            $('button.add-to-cart, button.add-to-cart-global', response.$productContainer)
                .attr('data-gtmdata', JSON.stringify(response.product.gtmData))
                .attr('data-gtmga4data', JSON.stringify(response.product.gtmGA4Data));
        });
    }
};

/**
 * @param {String} productId The product ID
 * @description gets the data for a product click
 */
function productClick(productObject) {
    var obj = {
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'actionField': { 'list': 'Search Results' },
                'products': []
            }
        }
    };
    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @param productId
 * @description Click event for add product to cart
 */
function addToCart(productObject, quantity) {
    var quantObj = { 'quantity': quantity },
        obj = {
            'event': 'addToCart',
            'ecommerce': {
                'add': {
                    'products': []
                }
            }
        };
    obj.ecommerce.add.products.push($.extend(productObject, quantObj));

    // dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object to prevent events affecting one another
    dataLayer.push(obj);
}

/**
 * @param productId
 * @description Click event for add product to cart
 */
function addToCartGA4(productObject, quantity) {
    var quantObj = { quantity: quantity };
    let cartInfo;
    const newProductObjects = [$.extend(productObject, quantObj)].map(item => {
        cartInfo = {
            cart_id: item.cart_id,
            date_created_cart: item.date_created_cart,
            date_update_cart: item.date_update_cart,
            total_quantity_cart: item.total_quantity_cart,
            total_price_cart: item.total_price_cart,
        };
        const itemInfo = item;
        delete itemInfo.cart_id;
        delete itemInfo.currencyCode;
        delete itemInfo.date_created_cart;
        delete itemInfo.date_update_cart;
        delete itemInfo.total_price_cart;
        delete itemInfo.total_quantity_cart;
        return itemInfo;
    });
    var obj = {
        'event': 'add_to_cart',
        'ecommerce': {
            ...cartInfo,
            'items': newProductObjects,
            'value': (Number(productObject.price) * Number(quantity)).toFixed(2),
            'currency': productObject.currency
        }
    };

    // dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object to prevent events affecting one another
    dataLayer.push(obj);
}

/**
 * @function removeFromCart
 * @description Click event for remove product from cart
 */
function removeFromCart(productObject, quantity) {
    var quantObj = { 'quantity': quantity };
    var obj = {
        'event': 'removeFromCart',
        'ecommerce': {
            'remove': {
                'products': []
            }
        }
    };
    obj.ecommerce.remove.products.push($.extend(productObject, quantObj));

    // dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object to prevent events affecting one another
    dataLayer.push(obj);
}

/**
 * @function removeFromCartGA4
 * @description Click event for remove product from cart
 */
function removeFromCartGA4(productObject, quantity) {
    var quantObj = { quantity: quantity };
    let cartInfo;
    const newProductObjects = [$.extend(productObject, quantObj)].map(item => {
        cartInfo = {
            cart_id: item.cart_id,
            date_created_cart: item.date_created_cart,
            date_update_cart: item.date_update_cart,
            total_quantity_cart: item.total_quantity_cart,
            total_price_cart: item.total_price_cart,
            currencyCode: item.currencyCode,
        };
        const itemInfo = item;
        delete itemInfo.currencyCode;
        delete itemInfo.cart_id;
        delete itemInfo.date_created_cart;
        delete itemInfo.date_update_cart;
        delete itemInfo.total_quantity_cart;
        delete itemInfo.total_price_cart;
        return itemInfo;
    });
    var obj = {
        'event': 'remove_from_cart',
        'ecommerce': {
            ...cartInfo,
            'currency': productObject.currency,
            'items': newProductObjects,
            'value': (Number(productObject.price) * Number(quantity)).toFixed(2),
        }
    };

    // dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object to prevent events affecting one another
    dataLayer.push(obj);
}

/**
 * @function pushEvent
 * @description Convenience method for creating a click event.
 * @param {String} event
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventlabel
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        'event': event,
        'eventCategory': eventCategory,
        'eventAction': eventAction,
        'eventLabel': eventLabel
    });
}

/**
 * @function init
 * @description Initialize the tag manager functionality
 * @param {String} nameSpace The current name space
 */
$(document).ready(function () {
    if (pageAction && events[pageAction]) {
        events[pageAction]();
    }
    events.all();
});

/**
 * listener for ajax events
 */
function gtmEventLoader() {
    try {
        $(document).ajaxSuccess(function (event, request, settings, data) {
            if (settings.dataTypes.indexOf('json') > -1) {
                if (data && '__gtmEvents' in data && Array.isArray(data.__gtmEvents)) {
                    data.__gtmEvents.forEach(function gtmEvent(gtmEvent) {
                        if (gtmEvent) {
                            // dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object to prevent events affecting one another
                            dataLayer.push(gtmEvent);
                        }
                    });
                }
            }
        });
        document.removeEventListener('DOMContentLoaded', gtmEventLoader);
    } catch (e) {
        console.error(e);
    }
}

/**
 * setup ajax event listener
 */
if (document.readyState === 'complete') {
    gtmEventLoader();
} else {
    document.addEventListener('DOMContentLoaded', gtmEventLoader);
}

/*
* Check for custom data attributes and add them to the dataLayer
*/
window.dataLayer = window.dataLayer || [];
// Page view event
let customData = document.querySelector('[data-gtmpageview]') ? document.querySelectorAll('[data-gtmpageview]') : null;
if (customData) {
    customData.forEach(function (data) {
        if (data.getAttribute('data-gtmpageview') !== 'null') {
            let pageGtmData = {
                'event': 'gtm.pageView',
                'pageURL': window.location.href,
                'pageName': data.getAttribute('data-gtmpageview'),
            };
            window.dataLayer.push(pageGtmData);
        }
    });
}

// PDP VIEW event
let prodGtmData = document.querySelector('[data-gtmdata]');
if (prodGtmData) {
    prodGtmData = prodGtmData ? prodGtmData.dataset.gtmdata : null;
    prodGtmData = JSON.parse(prodGtmData)
    prodGtmData.event = 'gtm.productView';
    window.dataLayer.push(prodGtmData);
}

// Product Tile View
let prodTileGtmData = document.querySelectorAll('[data-gtmdatatile]');
let prodTileDataList = [];
if (prodTileGtmData) {
    prodTileGtmData.forEach(function (item) {
        item = item.dataset.gtmdatatile;
        item = JSON.parse(item)
        prodTileDataList.push(item);
        prodTileDataList.event = 'gtm.productTileView';
    });
    window.dataLayer.push(prodTileDataList);
};

// Buttons Click event
let trackButtons = document.querySelectorAll('button');
trackButtons.forEach(function (button) {
    button.addEventListener('click', function (e) {
        let buttonGtmData = {
            'event': 'gtm.click',
            'buttonText': e.target.innerText
        };
        window.dataLayer.push(buttonGtmData);
    }
    );
})

// Links Click event
let trackLinks = document.querySelectorAll('a');
trackLinks.forEach(function (link) {
    link.addEventListener('click', function (e) {
        let linkGtmData = {
            'event': 'gtm.click',
            'linkText': e.target.innerText,
            'linkURL': e.target.href ? e.target.href : e.target.parentElement.href,
        };
        if (e.target.getAttribute('title')) {
            linkGtmData.productName = e.target.getAttribute('title');
        }
        window.dataLayer.push(linkGtmData);
    }
    );
});
